import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import HorizontalLogo from '../assets/horizontal.png';
import { leftFlex } from './common.styles';

const copyright = {
  pt: 5,
  borderTop: '1px solid',
  borderColor: 'divider',
  color: 'text.secondary',
};

function Copyright() {
  return (
    <Box sx={copyright}>
      <Typography variant="body2">
        {'Copyright © '}
        <Link href="https://kukierush.com/">Kukie Rush</Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

const footerLogo = {
  width: '100%',
  maxWidth: '285px',
  height: '100%',
  maxHeight: '50px',
};

export default function Footer() {
  return (
    <Container
      sx={{
        ...leftFlex,
        py: 4,
      }}
    >
      <Box sx={{ pb: 3 }}>
        <Box
          component="img"
          src={HorizontalLogo}
          sx={footerLogo}
          alt="logo of kukie rush"
        />
      </Box>
      <Copyright />
    </Container>
  );
}
