import Box from '@mui/material/Box';
import { flex } from './common.styles';
import * as React from 'react';
import Container from '@mui/material/Container';

export default function PlaylistEmbed() {
  return (
    <Container
      id="watch"
      sx={{
        ...flex,
        pb: { xs: 3, sm: 5 },
      }}
    >
      <Box
        sx={{
          boxShadow: 25,
          position: 'relative',
          width: '100%',
          pb: '56.25%',
        }}
      >
        <Box
          component="iframe"
          src="https://www.youtube.com/embed/videoseries?si=k3AoBAPHQvxXPjPz&list=PLCgt6-DDT6KOhDXm3HgZ0BHUg7zgWLuDN"
          title="Kukie Rush Playlist"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            border: 0,
          }}
        />
      </Box>
    </Container>
  );
}
