export const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const columnFlex = {
  ...flex,
  flexDirection: 'column',
};

export const endFlex = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
};

export const leftFlex = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  textAlign: 'left',
};

export const spanFlex = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};
