import { Donate } from 'react-kofi-overlay';
import * as React from 'react';
import Box from '@mui/material/Box';
import { columnFlex } from './common.styles';
import { Container, Typography } from '@mui/material';

export default function DonateButton() {
  return (
    <Container>
      <Box sx={{ ...columnFlex, pb: { xs: 3, sm: 4 }, gap: 2 }} id="donate">
        <Donate
          username="kukierush"
          styles={{
            donateBtn: {
              width: '200px',
              height: '60px',
              paddingBottom: '10px',
            },
            closeBtn: {
              marginTop: '100px',
            },
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Donate
          </Typography>
        </Donate>
        <Box
          component="iframe"
          src="https://ko-fi.com/streamalerts/goaloverlay/sa_17a64ec6-63e9-41e1-b476-18e9343cfb7f"
          sx={{
            border: 0,
            maxWidth: '500px',
            width: '100%',
            height: '55px',
          }}
        />
      </Box>
    </Container>
  );
}
