import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, alpha, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Schedule from './components/Schedule';
//import Countdown from './components/Countdown';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Speedrunners from './components/Speedrunners';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { PaletteMode } from '@mui/material';
import Donate from './components/Donate';
import PlaylistEmbed from './components/PlaylistEmbed';
//import StreamEmbed from './components/StreamEmbed';

const typography = {
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
};

const darkShadow = `0 0 24px 12px ${alpha('#033363', 0.2)}`;
const lightShadow = `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`;

const lightInfo = {
  info: {
    light: alpha('#fff', 0.4),
    main: '#fff',
    dark: '#eee',
  },
};

const darkInfo = {
  info: {
    light: alpha('#121212', 0.4),
    main: '#121212',
    dark: '#06090a',
  },
};

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const info = mode === 'dark' ? darkInfo : lightInfo;
  const defaultTheme = createTheme({
    palette: { mode, ...info },
    ...typography,
  });
  defaultTheme.shadows.push(mode === 'dark' ? darkShadow : lightShadow);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  //const startEpoch = 1722096000000;

  //const streamEnabled = startEpoch - +new Date() > 0;

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Donate />
      {/* streamEnabled ? (
        <Countdown targetDate={new Date(startEpoch)} />
      ) : (
        <StreamEmbed channel={'niftski'} />
      )*/}
      <PlaylistEmbed />
      <Schedule />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Speedrunners />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
