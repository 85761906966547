import * as React from 'react';
import { PaletteMode } from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Emblem from '../assets/emblem.png';
import NavOption from './NavOption';
import ColorToggle from './ColorToggle';
import { endFlex, spanFlex } from './common.styles';

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

const appBar = {
  boxShadow: 0,
  bgcolor: 'transparent',
  backgroundImage: 'none',
  mt: 2,
  position: 'fixed',
};

const toolbar = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: '999px',
  bgcolor: 'info.light',
  backdropFilter: 'blur(24px)',
  maxHeight: 40,
  border: '1px solid',
  borderColor: 'divider',
  boxShadow: '25',
};

const emblem = {
  width: '40px',
  height: '40px',
  '&:hover': {
    cursor: 'pointer',
  },
};

const navBox = {
  display: { xs: 'none', md: 'flex' },
  gap: 2,
};

const colorToggle = {
  display: { xs: 'none', md: 'flex' },
  alignItems: 'center',
  width: '40px',
  justifyContent: 'right',
};

const drawer = {
  minWidth: '60dvw',
  p: 2,
  backgroundColor: 'background.paper',
  flex: 1,
};

const drawerToggle = { minWidth: '30px', p: '4px', color: 'primary' };

function AppAppBar({ mode, toggleColorMode }: AppAppBarProps) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 84;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const openLink = (link: string) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const navOptions = (
    <>
      <NavOption
        text="Donate"
        onClick={() => openLink('https://ko-fi.com/kukierush')}
      />
      <NavOption text="Watch" onClick={() => scrollToSection('watch')} />
      <NavOption text="Schedule" onClick={() => scrollToSection('schedule')} />
      <NavOption text="Runners" onClick={() => scrollToSection('runners')} />
    </>
  );

  return (
    <Box>
      <AppBar sx={appBar}>
        <Container maxWidth="lg">
          <Toolbar variant="regular" sx={toolbar}>
            <Box sx={spanFlex}>
              <Box
                component="img"
                onClick={() => scrollToSection('hero')}
                src={Emblem}
                sx={emblem}
                alt="logo of kukie rush"
              />

              <Box sx={navBox}>{navOptions}</Box>
              <Box sx={colorToggle}>
                <ColorToggle mode={mode} toggleColorMode={toggleColorMode} />
              </Box>
            </Box>

            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={drawerToggle}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box sx={drawer}>
                  <Box sx={endFlex}>
                    <ColorToggle
                      mode={mode}
                      toggleColorMode={toggleColorMode}
                    />
                  </Box>
                  {navOptions}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default AppAppBar;
