import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as React from 'react';

interface NavOptionProps {
  text: string;
  onClick: () => void;
}

export default function NavOption(props: NavOptionProps) {
  const { text, onClick } = props;
  return (
    <MenuItem onClick={onClick} sx={{ py: '12px', px: '12px' }}>
      <Typography variant="h5" color="text.primary" fontWeight="medium">
        {text}
      </Typography>
    </MenuItem>
  );
}
