/* eslint-disable react/display-name */
import React, { useEffect, useState, useCallback, memo } from 'react';
import { Theme, alpha } from '@mui/material/styles';
import Papa from 'papaparse';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { columnFlex } from './common.styles';
import { fromUnixTime } from 'date-fns';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';

interface CsvRow {
  id: number;
  day: string | null;
  data: RowData;
}

interface RowData {
  [key: string]: string;
}

interface Header {
  id: number;
  text: string;
}

interface HidableCellProps {
  row: CsvRow;
  header: Header;
}

interface ExpandableRowProps {
  row: CsvRow;
  headers: Header[];
}

const includedColumns = [
  'Time',
  'Player(s)',
  'Game',
  'Category',
  'Format',
  'Platform(s)',
  'Estimate',
];
const mobileColumns = ['Time', 'Game'];

const cell = (header: Header) => ({
  display: !mobileColumns.includes(header.text)
    ? { xs: 'none', md: 'table-cell' }
    : '',
  backgroundColor: '',
  p: 1,
  minWidth: '80px',
});

const table = (theme: Theme) => ({
  mt: { xs: 2, sm: 2 },
  alignSelf: 'center',
  width: '100%',
  backgroundSize: 'cover',
  borderRadius: '10px',
  outline: '1px solid',
  outlineColor:
    theme.palette.mode === 'light'
      ? alpha('#BFCCD9', 0.5)
      : alpha('#9CCCFC', 0.1),
  boxShadow: '25',
});

const date = {
  backgroundColor: '#0363b1',
  textAlign: 'center',
  fontWeight: 'medium',
  p: 0.75,
};

function epochToLocalTime(msSinceEpoch: number) {
  const date = fromUnixTime(msSinceEpoch);
  return date.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: '2-digit',
  });
}

const HidableCell = memo((props: HidableCellProps) => {
  const { row, header } = props;
  const cellStyle = cell(header);
  let data = row.data[header.text];
  if (header.text === 'Time') {
    data = epochToLocalTime(Number(data));
  }

  if (header.text === 'Format') {
    cellStyle.backgroundColor =
      data === 'In-Person' ? '#33cd26' : data === 'Online' ? '#db910d' : '';
  }

  return <TableCell sx={cellStyle}>{data}</TableCell>;
});

const ExpandableRow = memo((props: ExpandableRowProps) => {
  const [open, setOpen] = useState(false);
  const { row, headers } = props;

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <>
      <TableRow
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          backgroundColor: row.id % 2 !== 0 ? 'info' : 'info.dark',
        }}
      >
        <TableCell sx={{ p: 0, display: { xs: 'table-cell', md: 'none' } }}>
          <IconButton aria-label="expand row" onClick={toggleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {headers.map((header) => (
          <HidableCell key={header.id} row={row} header={header} />
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={headers.length + 1}
          sx={{
            p: 0,
            display: { xs: 'table-cell', md: 'none' },
            borderBottom: open ? '' : 'none',
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              {headers.map(
                (header) =>
                  row.data[header.text] && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      key={header.id}
                      p={0.5}
                    >
                      <Typography fontWeight="medium" variant="body2" pr={0.5}>
                        {header.text}:
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {header.text === 'Time'
                          ? epochToLocalTime(Number(row.data['Time']))
                          : row.data[header.text]}
                      </Typography>
                    </Box>
                  ),
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
});

export default function Schedule() {
  const [headers, setHeaders] = useState<Header[]>([]);
  const [rows, setRows] = useState<CsvRow[]>([]);

  const fetchData = useCallback(async () => {
    const response = await fetch('./schedule.csv');
    const responseText = await response.text();
    const parsedData = Papa.parse<RowData>(responseText, { header: true });
    processParsedData(parsedData.data);
  }, []);

  const processParsedData = useCallback((data: RowData[]) => {
    const indexedHeaders: Header[] = [];
    Object.keys(data[0]).forEach((header, index) => {
      if (includedColumns.includes(header)) {
        indexedHeaders.push({
          id: index,
          text: header,
        });
      }
    });
    setHeaders(indexedHeaders);

    const indexedRows: CsvRow[] = [];
    const days: string[] = [];
    data.forEach((row, index) => {
      const date = fromUnixTime(Number(row['Time']));
      const day = date.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      if (!days.includes(day)) {
        days.push(day);
        indexedRows.push({
          id: index + days.length - 1,
          day: day,
          data: {},
        });
      }

      indexedRows.push({
        id: index + days.length,
        day: null,
        data: row,
      });
    });
    setRows(indexedRows);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container id="schedule" sx={columnFlex}>
      <Typography variant="h4" fontWeight="medium">
        Schedule
      </Typography>
      <Typography color="text.secondary" variant="body1">
        All times and dates are converted to your local timezone
      </Typography>
      <Box sx={(theme) => table(theme)}>
        <TableContainer component={Paper} id="schedule">
          <Table aria-label="schedule">
            <TableHead>
              <TableRow>
                <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }} />
                {headers.map((header) => (
                  <TableCell sx={cell(header)} key={header.id}>
                    {header.text}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <React.Fragment key={row.id}>
                  {row.day ? (
                    <TableRow>
                      <TableCell colSpan={headers.length + 1} sx={date}>
                        {row.day}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <ExpandableRow row={row} headers={headers} />
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}
