import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Niftski from '../assets/pfps/Niftski.png';
import Maximum from '../assets/pfps/Maximum.png';
import Jtknott from '../assets/pfps/jtknott.png';
import Stewie from '../assets/pfps/Stewie_Cartman.png';
import Scalpel from '../assets/pfps/scalpel.png';
import Tree from '../assets/pfps/tree_05.png';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { TwitchLogo } from './TwitchLogo';
import Twitter from '@mui/icons-material/Twitter';
import Link, { LinkProps } from '@mui/material/Link';
import { flex } from './common.styles';
import { Theme } from '@mui/material/styles';

interface Runner {
  pfp: string;
  name: string;
  color1: string;
  color2?: string;
  host: boolean;
  ytLink?: string;
  twitchLink?: string;
  twitterLink: string;
}

const runners: Runner[] = [
  {
    pfp: Niftski,
    name: 'Niftski',
    color1: '#EE4444',
    color2: '#E77471',
    host: true,
    ytLink: 'https://www.youtube.com/@Niftski',
    twitchLink: 'https://www.twitch.tv/Niftski',
    twitterLink: 'https://x.com/Niftski',
  },
  {
    pfp: Maximum,
    name: 'Maximum',
    color1: '#EE4444',
    color2: '#FF3091',
    host: true,
    ytLink: 'https://www.youtube.com/@Maximum',
    twitchLink: 'https://www.twitch.tv/ItsMaximum',
    twitterLink: 'https://x.com/ItsMaximum_',
  },
  {
    pfp: Jtknott,
    name: 'jtknott',
    color1: '#ee4444',
    host: true,
    ytLink: 'https://www.youtube.com/@jtknott0528',
    twitchLink: 'https://www.twitch.tv/jtknott05',
    twitterLink: 'https://x.com/jtknott05',
  },
  {
    pfp: Stewie,
    name: 'Stewie_Cartman',
    color1: '#6666EE',
    host: false,
    ytLink: 'https://www.youtube.com/@Stewie_Cartman',
    twitchLink: 'https://www.twitch.tv/stewie_cartman',
    twitterLink: 'https://x.com/Stew1e_Cartman',
  },
  {
    pfp: Scalpel,
    name: 'scalpel',
    color1: '#44BBEE',
    color2: '#C279E5',
    host: false,
    ytLink: 'https://www.youtube.com/@scalpel001',
    twitchLink: 'https://www.twitch.tv/scalpel001',
    twitterLink: 'https://x.com/scalpel001',
  },
  {
    pfp: Tree,
    name: 'tree_05',
    color1: '#44bbee',
    host: false,
    ytLink: 'https://www.youtube.com/@tree_0550',
    twitchLink: 'https://www.twitch.tv/tree_05',
    twitterLink: 'https://x.com/tree_smb',
  },
];

const dropShadow = 'drop-shadow(1.5px 1.5px 1px #000)';

const runnersBox = {
  pt: { xs: 4, sm: 12 },
  pb: { xs: 8, sm: 12 },
  color: 'primary',
  bgcolor: 'info.dark',
};

const container = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 3,
};

const header = {
  width: { sm: '100%', md: '80%' },
  textAlign: { sm: 'left', md: 'center' },
};

const runnerImage = {
  width: '70%',
  height: '70%',
  display: 'flex',
  alignSelf: 'center',
  WebkitFilter: dropShadow,
};

const runnerCard = (runner: Runner) => ({
  p: 1,
  height: '100%',
  border: '1px solid',
  borderColor: 'grey.200',
  background: 'transparent',
  boxShadow: runner.host ? `0 0 10px #FFD700` : '',
  backgroundColor: 'info.main',
});

const runnerName = (runner: Runner) => ({
  fontWeight: 'bold',
  fontSize: 'min(4vw, 2em)',
  position: 'relative',
  background: `linear-gradient(to right, ${runner.color1}, ${runner.color2 || runner.color1})`,
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  WebkitFilter: dropShadow,
});

const hostBadge = {
  width: 'min(7vw, 4em)',
  height: 'min(3.5vw, 2em)',
  backgroundColor: 'gold',
  borderRadius: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  WebkitFilter: dropShadow,
};

const hostText = {
  color: 'white',
  fontWeight: 'bold',
  fontStyle: 'italic',
  fontSize: 'min(2.5vw, 1.5em)',
  WebkitFilter: dropShadow,
};

const linkBox = (theme: Theme) => ({
  ...flex,
  color: theme.palette.mode === 'light' ? 'primary.dark' : '',
});

const linkProps: LinkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
  underline: 'none',
  color: 'inherit',
};

const logo = {
  fontSize: 'min(6vw, 3em)',
  WebkitFilter: dropShadow,
};

const customLogo = {
  width: 'min(6vw, 3em)',
  height: 'min(6vw, 3em)',
  WebkitFilter: dropShadow,
};

export default function Speedrunners() {
  return (
    <Box id="runners" sx={runnersBox}>
      <Container maxWidth="md" sx={container}>
        <Box sx={header}>
          <Typography variant="h3" fontWeight="medium" pb={1}>
            Featured Speedrunners
          </Typography>
          <Typography variant="h6" color="text.secondary">
            The speedrunners featured at this event will include:
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {runners.map((runner, index) => (
            <Grid item xs={6} md={4} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                sx={runnerCard(runner)}
              >
                <Box
                  component="img"
                  src={runner.pfp}
                  sx={runnerImage}
                  alt={runner.name + ' profile picture'}
                />

                <Box sx={flex} gap={{ xs: 0.75, sm: 1.5 }}>
                  <Typography sx={runnerName(runner)}>{runner.name}</Typography>
                  {runner.host && (
                    <Box sx={hostBadge}>
                      <Typography sx={hostText}>Host</Typography>
                    </Box>
                  )}
                </Box>

                <Box sx={(theme) => linkBox(theme)} gap={1}>
                  {runner.ytLink && (
                    <Link
                      href={runner.ytLink}
                      aria-label={runner.name + 's YouTube channel'}
                      {...linkProps}
                    >
                      <YouTubeIcon sx={logo} />
                    </Link>
                  )}
                  {runner.twitchLink && (
                    <Link
                      href={runner.twitchLink}
                      aria-label={runner.name + 's Twitch channel'}
                      {...linkProps}
                    >
                      <TwitchLogo style={customLogo} />
                    </Link>
                  )}
                  {runner.twitterLink && (
                    <Link
                      href={runner.twitterLink}
                      aria-label={runner.name + 's Twitter page'}
                      {...linkProps}
                    >
                      <Twitter sx={logo} />
                    </Link>
                  )}
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h4" color="text.primary">
          ... and more!
        </Typography>
      </Container>
    </Box>
  );
}
