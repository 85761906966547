import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import HorizontalLogo from '../assets/horizontal.png';
import { columnFlex } from './common.styles';
import { Theme, alpha } from '@mui/material/styles';

const background = (theme: Theme) => ({
  width: '100%',
  backgroundImage:
    theme.palette.mode === 'light'
      ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
      : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
  backgroundSize: '100% 20%',
  backgroundRepeat: 'no-repeat',
});

const container = {
  ...columnFlex,
  pt: { xs: 10, sm: 14 },
  pb: { xs: 3, sm: 4 },
};

const logo = {
  display: 'flex',
  alignSelf: 'center',
  width: '100%',
  maxWidth: '570px',
  height: '100%',
  maxHeight: '200px',
  pb: 1,
};

const subtext = {
  alignSelf: 'center',
  width: { sm: '100%', md: '80%' },
  color: 'text.secondary',
  textAlign: 'center',
  maxWidth: '500px',
};

export default function Hero() {
  return (
    <Box id="hero" sx={(theme) => background(theme)}>
      <Container sx={container}>
        <Box
          component="img"
          src={HorizontalLogo}
          sx={logo}
          alt="logo of kukie rush"
        />
        <Typography variant="h6" sx={subtext}>
          An in-person / online speedrunning event raising money to allow{' '}
          <Link href="https://youtu.be/6iz9bB0wIYQ" underline="none">
            LeKukie
          </Link>{' '}
          to attend{' '}
          <Link href="https://youtu.be/3WRWz7XG3Os" underline="none">
            PACE
          </Link>{' '}
          in 2025!
        </Typography>
      </Container>
    </Box>
  );
}
